import "@coreui/coreui-pro/dist/css/coreui.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import 'react-phone-number-input/style.css';
import axios from "axios";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const Home = React.lazy(() => import("./containers/Home/Home"));
const Charge = React.lazy(() => import("./containers/Charge/ChargeStart"));
const Phone = React.lazy(() => import("./containers/Charge/SubmitPhone"));
const Payment = React.lazy(() => import("./containers/Charge/PaymentDetails"));
const SessionStarted = React.lazy(
    () => import("./containers/Charge/SessionStarted"),
);
const UnavailableStation = React.lazy(
    () => import("./containers/Charge/UnavailableStation"),
);
const UserOffline = React.lazy(
    () => import("./containers/Charge/UserOffline"),
);
const StartRedirect = React.lazy(
    () => import("./containers/Charge/StartRedirect"),
);
const stripePublishableKey =
    process.env.REACT_APP_STRIPE_PUB_KEY ?? "missing stripe key";
const stripePromise = loadStripe(stripePublishableKey);

export default function App() {
    const qrCode = window.location.pathname.split("/")[2];
    const [jwt, setJwt] = useState<string | null>(localStorage.getItem('otc-token'));

    useEffect(() => {
        axios.post(
            `${process.env.REACT_APP_AUTH_ENDPOINT}/mobile/token`
        ).then(result => {
            localStorage.setItem(
                "otc-token",
                result.data.access_token,
            );
            setJwt(result.data.access_token)
            if(qrCode) localStorage.setItem(
                "otc-qrCode",
                qrCode
            );
        })
    }, [qrCode]);

    const appearance = {
        theme: "stripe",
    };
    const options: StripeElementsOptions = {
        mode: "setup",
        currency: "usd",
        paymentMethodCreation: "manual",
    };

    if(!jwt) return null;

    return (
        <Suspense fallback={loading}>
            <Elements options={options} stripe={stripePromise}>
                <Routes>
                    <Route path="*" element={<Home />} />
                    <Route path="/station/:chargieId/*" element={<Charge />} />
                    <Route path="/phone" element={<Phone />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route
                        path="/session-started"
                        element={<SessionStarted />}
                    />
                    <Route path="/unavailable" element={<UnavailableStation />} />
                    <Route path="/user-offline" element={<UserOffline />} />
                    <Route path="/startRedirect" element={<StartRedirect />} />

                </Routes>
            </Elements>
        </Suspense>
    );
}
